import { BrowserModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { GsaModule } from '@ade/gsa/src'

@NgModule({
    declarations: [AppComponent],
    imports: [BrowserModule, AppRoutingModule, GsaModule],
    providers: [],
    bootstrap: [AppComponent],
})
export class AppModule {}

import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'

const routes: Routes = [
    // {
    //     path: '',
    //     redirectTo: 'gsa/workflow',
    //     pathMatch: 'full',
    // },
]

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
